@font-face {
  font-family: "guyon_gazeboregular";
  src: url("headerFont/guyongazeboregular-k7aba-webfont.woff2") format("woff2"),
    url("headerFont/guyongazeboregular-k7aba-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
html{
  font-size: 100px;
}
body {
  margin: .15rem 0 0 .15rem;
  font-family: "Quicksand", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #f7dcad;
  background-color: #2e2836;
  border-top: 1.20rem solid #2e2836;
  border-left: 1.20rem solid #2e2836;
  border-bottom: 0;
  border-right: 0;
  border-image: url("./media/backgroundStripe.png");
  border-image-slice: 40% 40%;
  border-image-width: 1.20rem;
}
.App {
  max-width: 1300px;
  margin: auto;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
main section {
  padding-top: .65rem;
}
a{
  text-decoration: none;
  font-size: initial;
}
h1 {
  text-align: center;
  font-size: 72px;
  font-weight: 300;
  font-family: "guyon_gazeboregular", cursive;
  margin: 1em 0.25em 0.25em;
  letter-spacing: 1px;
}
h3 {
  font-size: 18px;
}
nav {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

/* nav a{
  margin: 8px;
}  */
header {
  background-color: #2e2836;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  padding: .1rem 0;
}
header div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
header h1 {
  margin: 0;
  position: static;
  font-size: .36rem;
  transform: none;
  display: inline-block;
  flex-grow: 1;
}
header nav {
  transform: none;
  position: static;
  flex-grow: 1;
  min-width: 380px;
}
header h3 {
  position: static;
  font-size: .18rem;
  transform: none;
  display: inline-block;
  flex-grow: 1;
  margin: 0;
}
h2 {
  text-align: center;
  font-size: 30px;
  font-weight: 300;
}

.proj-child h3 {
  width: 80%;
  text-align: center;
  background-color: #f7dcad;
  color: #2e2836;
  margin: 0 .24rem .12rem 0;
  padding: 0.5em .06rem 0.5em .30rem;
  border-bottom-right-radius: 0.75em;
  border-top-left-radius: 0.75em;

  box-shadow: .08rem .04rem #f2b326, 0rem .04rem #f2b326, .08rem 0rem #f2b326,
    .16rem .04rem #e4722c, .16rem 0rem #e4722c, .24rem .04rem #df3b32, .24rem 0rem #df3b32;
}

button {
  font-family: inherit;
  font-size: .14rem;
  color: #f7dcad;
  padding: .05rem .10rem;
  margin: .05rem;
  border: none;
  border: 2px solid #f7dcad;
  background-color: #2e2836;
  border-radius: .10rem;
  transition: 300ms;
}
.backend {
  display: inline-block;
}
p {
  font-size: 16px;
  margin: 0;
  background-color: #ffffff25;
  padding: .30rem;
  border-radius: .15rem;
  min-height: 2.70rem;
  line-height: 1.5;
}
button:hover {
  box-shadow: .02rem .03rem #df3b32, .04rem .06rem #f2b326, .06rem .09rem #50bab6;
  transition: 300ms;
}
.headshot {
  width: 240px;
  border-radius: 20px;
  box-shadow: 0 0 0 6px #f7dcad, 0 0 0 12px #2ba0a4, 0 0 0 18px #156a7f;
  margin: 18px;
}
.nontext{
  float: left;
  margin: .40rem .44rem .05rem .44rem;
}
.resume>div>a>button{
  display: block;
  width: 90%;
  margin: 0 auto 15px auto;
  font-size: 18px;
}

#landing {
  width: 100%;
  height: 100vh;
  margin: 0 0 10vh 0;
}
#landing div {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.circle {
  width: 200px;
  height: 200px;
  overflow: hidden;
  border-radius: 50%;
  border: 8px solid #156a7f6b;
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
}
.proj-img {
  height: 100%;
  /* transform: translateX(-25%); */
}
.proj-parent {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;

}
.proj-child {
  font-size: .1rem;
  flex: 0 1 350px;
  min-width: 225px;
  margin: .30rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.proj-child p {
  width: 80%;
  min-height: auto;
}
.proj-stack {
  font-weight: 100;
  font-size: 14px;
  font-style: italic;
  margin: 8px;
}
.proj-desc {
  font-weight: 200;
  margin: 8px;
}
.contactInfo>p{
  margin: 0 auto;
  display: block;
  min-height: 0;
  text-align: center;
  width: fit-content;
}
.contactInfo>p>a>img{
  margin: 30px;
}
.area {
  background: #2e2836;
  width: 100%;
  height: 10vh;
}

.flowers {
  z-index: -1;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 1.20rem;
  left: 1.20rem;
  width: calc(100vw - 135px);
  height: 100vh;
  overflow: hidden;
}

.flowers li {
  position: absolute;
  display: block;
  visibility: hidden;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0);
  background-image: url("./media/flower2.png");
  background-size: contain;
  background-repeat: no-repeat;
  animation: animate 25s linear infinite;
  bottom: 0px;
}

/* FALLING FLOWERS */

.flowers li:nth-child(1) {
  /* left to right: #4 */
  left: 25%;
  width: 40px;
  height: 40px;
  animation-delay: -1s;
}

.flowers li:nth-child(2) {
  /* left to right: #1 */
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 5s;
  animation-duration: 12s;
}

.flowers li:nth-child(3) {
  /* left to right: #10 */
  left: 60%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
}

.flowers li:nth-child(4) {
  /* left to right: #17a */
  left: 95%;
  width: 30px;
  height: 30px;
  animation-delay: -7s;
  animation-duration: 27s;
}

.flowers li:nth-child(5) {
  /* left to right: #11a */
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 10s;
}

.flowers li:nth-child(6) {
  /* left to right: #13 */
  left: 75%;
  width: 5px;
  height: 5px;
  animation-delay: 3s;
}

.flowers li:nth-child(7) {
  /* left to right: #5a */
  left: 35%;
  width: 10px;
  height: 10px;
  animation-delay: -2s;
}

.flowers li:nth-child(8) {
  /* left to right: #8c */
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: -10s;
  animation-duration: 50s;
}

.flowers li:nth-child(9) {
  /* left to right: #0 */
  left: 5%;
  width: 15px;
  height: 15px;
  animation-delay: 0s;
  animation-duration: 35s;
}

.flowers li:nth-child(10) {
  /* left to right: #15b */
  left: 85%;
  width: 10px;
  height: 10px;
  animation-delay: 0s;
  animation-duration: 11s;
}

.flowers li:nth-child(11) {
  /* left to right: #9 */
  left: 55%;
  width: 40px;
  height: 40px;
  animation-delay: 0s;
  animation-duration: 30s;
}

.flowers li:nth-child(12) {
  /* left to right: #2 */
  left: 15%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.flowers li:nth-child(13) {
  /* left to right: #12 */
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
  animation-duration: 22s;
}

.flowers li:nth-child(14) {
  /* left to right: #6 */
  left: 40%;
  width: 30px;
  height: 30px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.flowers li:nth-child(15) {
  /* left to right: #11b */
  left: 65%;
  width: 30px;
  height: 30px;
  animation-delay: 0s;
}

.flowers li:nth-child(16) {
  /* left to right: #7 */
  left: 45%;
  width: 5px;
  height: 5px;
  animation-delay: 3s;
  animation-duration: 18s;
}

.flowers li:nth-child(17) {
  /* left to right: #5b */
  left: 35%;
  width: 10px;
  height: 10px;
  animation-delay: 7s;
}

.flowers li:nth-child(18) {
  /* left to right: #8b */
  left: 50%;
  width: 15px;
  height: 15px;
  animation-delay: -15s;
  animation-duration: 40s;
}

.flowers li:nth-child(19) {
  /* left to right: #3c */
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: -12s;
  animation-duration: 35s;
}
.flowers li:nth-child(20) {
  /* left to right: #17b */
  left: 95%;
  width: 15px;
  height: 15px;
  animation-delay: -11s;
  animation-duration: 28s;
}

.flowers li:nth-child(21) {
  /* left to right: #11c */
  left: 65%;
  width: 10px;
  height: 10px;
  animation-delay: 10s;
}

.flowers li:nth-child(22) {
  /* left to right: #3d */
  left: 20%;
  width: 5px;
  height: 5px;
  animation-delay: 15s;
  animation-duration: 18s;
}

.flowers li:nth-child(23) {
  /* left to right: #15a */
  left: 85%;
  width: 10px;
  height: 10px;
  animation-delay: 12s;
}

.flowers li:nth-child(24) {
  /* left to right: #14 */
  left: 80%;
  width: 25px;
  height: 25px;
  animation-delay: -10s;
  animation-duration: 40s;
}

.flowers li:nth-child(25) {
  /* left to right: #16 */
  left: 90%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 32s;
}
@keyframes animate {
  0% {
    transform: translateY(-100vh) rotate(0deg);
    opacity: 0.2;
    visibility: visible;
  }

  100% {
    transform: translateY(0px) rotate(720deg);
    opacity: 0;
  }
}

@media screen and (max-width: 800px){
  header div {
    flex-wrap:wrap;
}
header nav {
    min-width:auto;
}
}

@media screen and (max-width: 650px) {
  .nontext{
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin: .40rem 1rem .05rem 1rem;

  }
  .nontext img{
    width: 100%;
    margin-left: 0;
  }
  button {
    font-size:75%
}
}


@media screen and (max-width: 500px) {
  html{
    font-size: 50px;
  }
  .flowers{
    width: calc(100vw - (135px/2));
  }
  .nontext{
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
  .nontext img{
    width: 100%;
    margin-left: 0;
  }
}